import styled from '@emotion/styled';
import { breakpoints, colors, spacing } from '../../utils/styleguide';
import Typography, { TypographyVariants } from './text/Typography';
import { ReactNode, useRef } from 'react';
import useComponentInViewport from '../../utils/hooks/useComponentInViewport';

const Wrapper = styled.div`
  padding: ${spacing[1]}px 0 ${spacing[1]}px ${spacing[4]}px;
  border-left: 2px solid;
  border-color: ${({ isVisible }: { isVisible: boolean }) =>
    isVisible ? colors.purple400 : colors.grey03};

  transition: border-color ease-out 300ms;

  ${breakpoints.desktop} {
    padding: ${spacing[1]}px 0 ${spacing[1]}px ${spacing[5]}px;
  }
`;

const StyledSectionTitle = styled(Typography)`
  color: ${({ color }) => (color ? color : colors.blackSecondary)};
`;

type Props = {
  children?: ReactNode;
  color?: string;
  title: string;
  variant?: TypographyVariants;
};

export default function SectionTitle({ children, color, title, variant = 'overline' }: Props) {
  const ref = useRef<HTMLHeadingElement>(null);
  const isVisible = useComponentInViewport(ref);

  return (
    <Wrapper isVisible={isVisible}>
      <StyledSectionTitle ref={ref} variant={variant} color={color}>
        {title}
      </StyledSectionTitle>
      {children}
    </Wrapper>
  );
}
